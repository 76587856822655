<template>
    <div class="carryOnCode">
        <div class="content">
            <div id="qrcodea"></div>
            <!-- <img src="xx" alt=""> -->
        </div>
        <div class="btn" @click="goBack">返回首页</div>
    </div>
  </template>
  
  <script>
  import { Image,Toast,Dialog,Stepper } from "vant";
  import { mapState } from "vuex";
  import QRCode from "qrcodejs2";
  export default {
    name: "carryOn",
    components: {
      [Image.name]: Image,
      [Dialog.Component.name]: Dialog.Component,
      [Stepper.name]:Stepper
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.userInfo,
      }),
      isMiniprogram(){
        return window.__wxjs_environment === 'miniprogram' 
      }
    },
    data() {
      
      return {
        value:1,
        qrcodeObj:null,
      };
    },
    mounted() {
      this.getQrcode()
    },
    methods: {
        getQrcode(){
          const {name,phone,projId,cunPhone} = this.$route.query;
          if (this.qrcodeObj) {
              let qrcodeHtml = document.getElementById("qrcodea");
              qrcodeHtml.innerHTML = "";
          }
          this.qrcodeObj = new QRCode("qrcodea", {
              text: cunPhone + " " + phone + " " + projId,
              width: 220,
              height: 220,
              colorDark: "#000000",
              colorLight: "#ffffff",
              // correctLevel: QRCode.CorrectLevel.H,
          });
        },
        goBack(){
            this.$router.push({name:'index'})
        }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .carryOnCode {
    width: 100%;
    height: 100vh;
    padding-top: 10px;
    background: #f5f7fa;
    .content{
        height: calc(100vh - 10px);
        background-color: #fff;
        background-image: url('~@/assets/carryOn/qrCodeBg.png');
        background-repeat: no-repeat;
        background-size: 286px 386px;
        background-position: center 94px;
        padding-top: 180px;
        display: flex;
        justify-content: center;
        #qrcodea{
            // position: absolute;
            width: 222px;
            height: 228px;
            background-color: #fff;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .btn{
        color: #fff;
        background-color: #1989F7;
        border-radius: 4px;
        position: absolute;
        font-size: 16px;
        text-align: center;
        line-height: 38px;
        width: calc(100% - 32px);
        left: 16px;
        bottom: 38px;
    }
  }
  </style>